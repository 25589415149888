import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Container, Navigation } from '../components'

import { DefaultLayout } from '../layouts/defaultlayout'

const flatten = (text, child) => {
  return typeof child === 'string' ? text + child : React.Children.toArray(child.props.children).reduce(flatten, text)
}

const renderers = {
  heading: ({ children, level }) => {
    const childs = React.Children.toArray(children)
    const text = childs.reduce(flatten, '')
    const slug = text.toLowerCase().replace(/\W/g, '-')
    return <div>{React.createElement(`h${level}`, { id: slug }, childs)}</div>
  },
}

interface MarkDownProps {
  content: string
  logo: any
}

const menu = [{ title: 'Home', path: '/' }]

const MarkDown = ({ content, logo }: MarkDownProps) => (
  <DefaultLayout>
    <Navigation title="Yogastudio Monheim" menu={menu} logo={logo} />
    <Container>
      <ReactMarkdown renderers={renderers} source={content} />
    </Container>
  </DefaultLayout>
)

export { MarkDown }
